import type { RoutingAction } from 'behavior/routing/actions';
import type { OrderTemplate } from '../orderTemplates/types';
import {
  OrderTemplateDetailsReceivedAction,
  OrderTemplateLineUomChangedAction,
  OrderTemplateLineRemovedAction,
  OrderTemplateClearedQuantitiesAction,
  OrderTemplateLineChangedQuantityAction,
  OrderTemplateShareChangedAction,
  OrderTemplateDetailsAction,
  OrderTemplateDetailsAddedLineAction,
  OrderTemplateSavedChangesAction,
  ORDER_TEMPLATE_DETAILS_RECEIVED,
  ORDER_TEMPLATE_LINE_UOM_CHANGED,
  ORDER_TEMPLATE_LINE_REMOVED,
  ORDER_TEMPLATE_CLEARED_QUANTITIES,
  ORDER_TEMPLATE_LINE_CHANGED_QUANTITY,
  ORDER_TEMPLATE_SHARE_CHANGED,
  ORDER_TEMPLATE_ADDED_PRODUCT,
  ORDER_TEMPLATE_SAVED_CHANGES,
} from 'behavior/pages/orderTemplateDetails/actions';
import { createReducer } from 'utils/redux';
import { PageComponentNames } from '../componentNames';
import itemList from 'utils/structureData/itemList';

type State = {
  component: PageComponentNames.OrderTemplateDetails;
  item: OrderTemplate | null;
};

export default createReducer<State, OrderTemplateDetailsAction | RoutingAction>(null as unknown as State, {
  [ORDER_TEMPLATE_DETAILS_RECEIVED]: onOrderTemplateDetailsReceived,
  [ORDER_TEMPLATE_LINE_UOM_CHANGED]: onOrderTemplateLineUomChanged,
  [ORDER_TEMPLATE_LINE_REMOVED]: onOrderTemplateLiveRemoved,
  [ORDER_TEMPLATE_CLEARED_QUANTITIES]: onOrderTemplateClearedQuantities,
  [ORDER_TEMPLATE_LINE_CHANGED_QUANTITY]: onOrderTemplateLineQuantityChanged,
  [ORDER_TEMPLATE_SHARE_CHANGED]: onOrderTemplateShareChanged,
  [ORDER_TEMPLATE_ADDED_PRODUCT]: onOrderTemplateLineAdded,
  [ORDER_TEMPLATE_SAVED_CHANGES]: onOrderTemplateSavedChanges,
});

function onOrderTemplateDetailsReceived(state: State, action: OrderTemplateDetailsReceivedAction) {
  return {
    ...state,
    item: action.payload,
  };
}

function onOrderTemplateLineUomChanged(state: State, action: OrderTemplateLineUomChangedAction) {
  
  if (!state.item || !state.item.lines)
    return state;
  
  const { lines: stateLines } = state.item;
  let changed = false;

  const updatedLines = stateLines.map(line => {
    const newSubLines = line.subLines.map(subLine => {
        if(subLine.id !== action.payload.lineId)
          return subLine;

        subLine.uomId = action.payload.uomId;
        changed = true;

        return { ...subLine, uomId: action.payload.uomId};
    });

    return { ...line, subLines: newSubLines};
  });

  return changed
  ? { ...state, item: { ...state.item, lines: updatedLines } }
  : state;
}

function onOrderTemplateLiveRemoved(state: State, action: OrderTemplateLineRemovedAction) {
  if (!state.item || !state.item.lines)
    return state;

  const { lines: stateLines } = state.item;

  let updateLines = stateLines.map(line=>{
    const subLines = line.subLines.filter(t => t.id !== action.payload.lineId);
    return { ...line, subLines };
  });

  updateLines = updateLines.filter(l => l.subLines.length > 0);
  
  return {
    ...state, item: { ...state.item, lines: updateLines },
  };
}

function onOrderTemplateClearedQuantities(state: State, action: OrderTemplateClearedQuantitiesAction) {
  
  if (!state.item || !state.item.lines)
    return state;
  
  const { lines: stateLines } = state.item;

  const updatedLines = stateLines.map(line => {
    const newSubLines = line.subLines.map(subLine => {
        subLine.quantity = 0;

        return { ...subLine, quantity: 0};
    });

    return { ...line, subLines: newSubLines};
  });

  return { ...state, item: { ...state.item, lines: updatedLines } };
}

function onOrderTemplateLineQuantityChanged(state: State, action: OrderTemplateLineChangedQuantityAction) {
  
  if (!state.item || !state.item.lines)
    return state;
  
  const { lines: stateLines } = state.item;
  let changed = false;

  const updatedLines = stateLines.map(line => {
    const newSubLines = line.subLines.map(subLine => {
        if(subLine.id !== action.payload.lineId)
          return subLine;

        subLine.quantity = action.payload.quantity;
        changed = true;

        return { ...subLine, quantity: action.payload.quantity};
    });

    return { ...line, subLines: newSubLines};
  });

  const newItem = { ...state.item, lines: updatedLines };

  return changed
  ? { ...state, item: newItem }
  : state;
}

function onOrderTemplateShareChanged(state: State, action: OrderTemplateShareChangedAction) {
  if (!state.item)
    return state;

  return { ...state, item: { ...state.item, shared: !state.item.shared },
  };
}

function onOrderTemplateLineAdded(state: State, action: OrderTemplateDetailsAddedLineAction) {
  
  if (!state.item || !state.item.lines)
    return state;
  
  const { lines: stateLines } = state.item;
  const { line: newLine } = action.payload;

  if(!newLine || !newLine.subLines)
    return state;

  const hasProduct = stateLines.some(l=>l.product.id === newLine.product.id);
  if(!hasProduct)
  {
    let updatedLines = stateLines;
    updatedLines.push(newLine);
    return { ...state, item: { ...state.item, lines: updatedLines } }
  }

  const updatedLines = stateLines.map(line => {
    if(line.product.id !== newLine.product.id)
      return line;
    else
    {
      const newSubLine = newLine.subLines[0];
      const hasSubLine = line.subLines.some(sl=>sl.variantId === newSubLine.variantId && sl.uomId === newSubLine.uomId);

      if(!hasSubLine)
      {
        let newSubLines =  line.subLines;
        newSubLines.push(newSubLine);
        return { ...line, subLines: newSubLines};
      }

      const newSubLines = line.subLines.map(subLine => {
          if(subLine.variantId !== newSubLine.variantId || subLine.uomId !== newSubLine.uomId)
            return subLine;

          const newQuantity = subLine.quantity + newSubLine.quantity;

          return { ...subLine, quantity: newQuantity };
      });

      return { ...line, subLines: newSubLines};
    }
  });

  return { ...state, item: { ...state.item, lines: updatedLines } };
}


function onOrderTemplateSavedChanges(state: State, action: OrderTemplateSavedChangesAction) {
  return { ...state, templateSaveChangesResult: action.payload.savingResult };
}