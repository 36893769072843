import type {
    OrderTemplate,
    OrderTemplateAddProductInput,
    OrderTemplateLine,
    OrderTemplateLineInput,
    SaveOrderTemplateResult,
  } from '../orderTemplates/types';

export const ORDER_TEMPLATE_DETAILS_REQUESTED = 'ORDER_TEMPLATE_DETAILS_REQUESTED' as const;
export const loadOrderTemplateDetails = (id: string) => ({
  type: ORDER_TEMPLATE_DETAILS_REQUESTED,
  payload: { id },
});

export const ORDER_TEMPLATE_DETAILS_RECEIVED = 'ORDER_TEMPLATE_DETAILS_RECEIVED' as const;
export const orderTemplateDetailsReceived = (orderTemplates: OrderTemplate[] | null) => ({
  type: ORDER_TEMPLATE_DETAILS_RECEIVED,
  payload: orderTemplates ? orderTemplates[0] : null,
});
export type OrderTemplateDetailsReceivedAction = ReturnType<typeof orderTemplateDetailsReceived>;

export const ORDER_TEMPLATE_LINE_UOM_CHANGED = 'ORDER_TEMPLATE_LINE_UOM_CHANGED' as const;
export const orderTemplateLineUomChanged = (lineId: string, uomId: string) => ({
  type: ORDER_TEMPLATE_LINE_UOM_CHANGED,
  payload: { lineId, uomId },
});
export type OrderTemplateLineUomChangedAction = ReturnType<typeof orderTemplateLineUomChanged>;

export const ORDER_TEMPLATE_LINE_REMOVED = 'ORDER_TEMPLATE_LINE_REMOVED' as const;
export const orderTemplateLineRemoved = (lineId: string) => ({
  type: ORDER_TEMPLATE_LINE_REMOVED,
  payload: { lineId },
});
export type OrderTemplateLineRemovedAction = ReturnType<typeof orderTemplateLineRemoved>;

export const ORDER_TEMPLATE_CLEARED_QUANTITIES = 'ORDER_TEMPLATE_CLEARED_QUANTITIES' as const;
export const orderTemplateClearedQuantities = () => ({
  type: ORDER_TEMPLATE_CLEARED_QUANTITIES,
  payload: { },
});
export type OrderTemplateClearedQuantitiesAction = ReturnType<typeof orderTemplateClearedQuantities>;

export const ORDER_TEMPLATE_LINE_CHANGED_QUANTITY = 'ORDER_TEMPLATE_LINE_CHANGED_QUANTITY' as const;
export const orderTemplateLineChangedQuantity = (lineId: string, quantity: number) => ({
  type: ORDER_TEMPLATE_LINE_CHANGED_QUANTITY,
  payload: { lineId, quantity },
});
export type OrderTemplateLineChangedQuantityAction = ReturnType<typeof orderTemplateLineChangedQuantity>;

export const ORDER_TEMPLATE_SHARE_CHANGED = 'ORDER_TEMPLATE_SHARE_CHANGED' as const;
export const orderTemplateShareChanged = () => ({
  type: ORDER_TEMPLATE_SHARE_CHANGED,
  payload: { },
});
export type OrderTemplateShareChangedAction = ReturnType<typeof orderTemplateShareChanged>;

export const ORDER_TEMPLATE_ADD_PRODUCT = 'ORDER_TEMPLATE_ADD_PRODUCT' as const;
export const addProductToTemplate = (line: OrderTemplateAddProductInput) => ({
  type: ORDER_TEMPLATE_ADD_PRODUCT,
  payload: { line },
});

export const ORDER_TEMPLATE_ADDED_PRODUCT = 'ORDER_TEMPLATE_ADDED_PRODUCT' as const;
export const addedProductToTemplate = (line: OrderTemplateLine | null) => ({
  type: ORDER_TEMPLATE_ADDED_PRODUCT,
  payload: { line },
});
export type OrderTemplateDetailsAddedLineAction = ReturnType<typeof addedProductToTemplate>;

export const ORDER_TEMPLATE_SAVE_CHANGES = 'ORDER_TEMPLATE_SAVE_CHANGES' as const;
export const orderTemplateSaveChanges = (id: string, shared: boolean, lines: OrderTemplateLineInput[]) => ({
  type: ORDER_TEMPLATE_SAVE_CHANGES,
  payload: { id, shared, lines },
});

export const ORDER_TEMPLATE_SAVED_CHANGES = 'ORDER_TEMPLATE_SAVED_CHANGES' as const;
export const orderTemplateSavedChanges = (savingResult: SaveOrderTemplateResult) => ({
  type: ORDER_TEMPLATE_SAVED_CHANGES,
  payload: { savingResult },
});

export type OrderTemplateSavedChangesAction = ReturnType<typeof orderTemplateSavedChanges>;

export type OrderTemplateDetailsAction = ReturnType<
  | typeof loadOrderTemplateDetails
  | typeof orderTemplateDetailsReceived
  | typeof orderTemplateLineUomChanged
  | typeof orderTemplateLineRemoved
  | typeof orderTemplateClearedQuantities
  | typeof orderTemplateLineChangedQuantity
  | typeof orderTemplateShareChanged
  | typeof addedProductToTemplate
  | typeof addProductToTemplate
  | typeof orderTemplateSaveChanges
  | typeof orderTemplateSavedChanges
>;