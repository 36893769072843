import { ofType } from 'redux-observable';
import { combineEpics } from 'utils/rxjs';
import {
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import {
  WAREHOUSES_REQUESTED,
  SELECTEDWAREHOUSE_REQUESTED,
  SELECT_WAREHOUSE,
  warehousesLoaded,
  WarehousesAction,
  selectedWarehouseLoaded,
} from './actions';
import { warehousesQuery, selectedWarehouseQuery, selectedWarehouseMutation } from './queries';
import type { Epic } from 'behavior/types';
import { SelectedWarehouseType, WarehouseType } from './types';
import { addAbilitiesToExpiredAbilities } from 'behavior/user/actions';
import { AbilityTo } from 'behavior/user';

type WarehousesQueryResponse = {
  warehouses: Array<WarehouseType>;
};

type SelectedWarehouseQueryResponse = {
  viewer: {
    selectedWarehouse: SelectedWarehouseType;
  };
};

type SelecteWarehouseMutationResponse = {
  profile: {
    selectWarehouse: SelectedWarehouseType;
  };
};

const warehousesEpic: Epic<WarehousesAction> = (action$, _state$, { api }) => action$.pipe(
  ofType(WAREHOUSES_REQUESTED),
  switchMap(_ => api.graphApi<WarehousesQueryResponse>(warehousesQuery).pipe(
    map(data => warehousesLoaded(data.warehouses)),
  )),
);

const selectedWarehouseEpic: Epic<WarehousesAction> = (action$, _state$, { api }) => action$.pipe(
  ofType(SELECTEDWAREHOUSE_REQUESTED),
  switchMap(_ => api.graphApi<SelectedWarehouseQueryResponse>(selectedWarehouseQuery).pipe(
    map(data => selectedWarehouseLoaded(data.viewer.selectedWarehouse)),
  )),
);

const selectWarehouseEpic: Epic<WarehousesAction> = (action$, _state$, { api }) => action$.pipe(
  ofType(SELECT_WAREHOUSE),
  switchMap(action => api.graphApi<SelecteWarehouseMutationResponse>(selectedWarehouseMutation, {
    input: action.payload,
  }, { retries: 0 }).pipe(
    mergeMap(data => [selectedWarehouseLoaded(data.profile.selectWarehouse), addAbilitiesToExpiredAbilities([AbilityTo.CloseSelectWarehousePopup])]),
  ),
  ),
);

export default combineEpics(
  warehousesEpic,
  selectedWarehouseEpic,
  selectWarehouseEpic,
);