import { memo } from 'react';
import { useSelector } from 'react-redux';
import { renderRows, extendDefaultSettings } from 'components/containers';
import { desktop as defaultDesktop } from './defaultContent';

const DesktopHeaderTemplate = () => {
  const desktop = useSelector(state => state.header.desktop);
  return renderRows(desktop ?? extendDefaultSettings(defaultDesktop));
};

export default memo(DesktopHeaderTemplate);
