import {
  WAREHOUSES_LOADED,
  SELECTEDWAREHOUSE_LOADED,
  WarehousesAction,
} from './actions';
import { createReducer } from 'utils/redux';
import type { SelectedWarehouseType, WarehouseType } from './types';

export type WarehousesState = {
  warehouses: Array<WarehouseType>;
  selectedWarehouse: SelectedWarehouseType;
};

const initialState: WarehousesState = {
  warehouses: [],
  selectedWarehouse: null,
};

export default createReducer<WarehousesState, WarehousesAction>(initialState, {
  [WAREHOUSES_LOADED]: onWarehousesLoaded,
  [SELECTEDWAREHOUSE_LOADED]: onSelectedWarehouseLoaded,
});

function onWarehousesLoaded(state: WarehousesState, action: { payload: Array<WarehouseType> }): WarehousesState {
  const warehouses = action.payload;

  return {
    ...state,
    warehouses,
  };
}

function onSelectedWarehouseLoaded(state: WarehousesState, action: { payload: SelectedWarehouseType }): WarehousesState {
  const selectedWarehouse = action.payload;
  
  return {
    ...state,
    selectedWarehouse,
  };
}