import type { SelectedWarehouseType, WarehouseType } from './types';

export const WAREHOUSES_LOADED = 'WAREHOUSES/LOADED' as const;
export const warehousesLoaded = (warehouses: Array<WarehouseType>) => ({ type: WAREHOUSES_LOADED, payload: warehouses });

export const WAREHOUSES_REQUESTED = 'WAREHOUSES/REQUESTED' as const;
export const loadWarehouses = () => ({ type: WAREHOUSES_REQUESTED });

export const SELECTEDWAREHOUSE_LOADED = 'SELECTEDWAREHOUSE/LOADED' as const;
export const selectedWarehouseLoaded = (warehouses: SelectedWarehouseType) => ({ type: SELECTEDWAREHOUSE_LOADED, payload: warehouses });

export const SELECTEDWAREHOUSE_REQUESTED = 'SELECTEDWAREHOUSE/REQUESTED' as const;
export const getSelectedWarehouse = () => ({ type: SELECTEDWAREHOUSE_REQUESTED });

export const SELECT_WAREHOUSE = 'SELECTWAREHOUSE' as const;
export const selectWarehouse = (warehouseId: string) => ({ type: SELECT_WAREHOUSE, payload: warehouseId });

export type WarehousesAction = ReturnType<
  | typeof warehousesLoaded
  | typeof loadWarehouses
  | typeof getSelectedWarehouse
  | typeof selectedWarehouseLoaded
  | typeof selectWarehouse
  >;
