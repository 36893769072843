import type { Action } from 'redux';
import type { AppState } from 'behavior';
import type { Epic } from 'behavior/types';
import type { OrderTemplate, OrderTemplateLine, SaveOrderTemplateResult } from '../orderTemplates/types';
import { loadOrderTemplateLines } from '../orderTemplates/actions';
import type { ModifiedLines } from 'behavior/basket/types';
import { basketChangeStarted, basketChangeCompleted, navigateTo } from 'behavior/events';
import { trackAddToBasket, getProductsTrackingDataFromLines } from 'behavior/analytics';
import { routesBuilder } from 'routes';
import { LoadedSettings } from 'behavior/settings';
import { concatToIfEmpty } from 'utils/rxjs';
import {
  orderTemplateDetailsQuery,
  addProductMutation,
  saveChangesMutation,
} from './queries';
import {
  ORDER_TEMPLATE_DETAILS_REQUESTED,
  ORDER_TEMPLATE_ADD_PRODUCT,
  ORDER_TEMPLATE_SAVE_CHANGES,
  orderTemplateDetailsReceived,
  OrderTemplateDetailsAction,
  addedProductToTemplate,
  orderTemplateSavedChanges,
} from './actions';
import { mergeMap, map, takeUntil, startWith, switchMap } from 'rxjs/operators';
import { combineEpics, ofType } from 'redux-observable';
import { LOCATION_CHANGED } from 'behavior/events';
import { retryWithToast } from 'behavior/errorHandling';
import { of, from } from 'rxjs';

type OrderTemplateDetailsResponse = {
  orderTemplates: OrderTemplate[] | null;
};

type OrderTemplateLinesResponse = {
  orderTemplates: { lines: OrderTemplateLine[] }[] | null;
};

type AddedOrderTemplateLineResponse = {
  orderTemplates: {
    addProduct: OrderTemplateLine | null;
  };
};

const loadOrderTemplateDetailsEpic: Epic<OrderTemplateDetailsAction> = (action$, state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(ORDER_TEMPLATE_DETAILS_REQUESTED),
    mergeMap(action => api.graphApi<OrderTemplateDetailsResponse>(orderTemplateDetailsQuery, {
      ...action.payload,
      loadCategories: state$.value.analytics?.isTrackingEnabled,
    }).pipe(
      map(({ orderTemplates }) =>
        orderTemplateDetailsReceived(orderTemplates)),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    ),
    ),
  );
};

const addLineToOrderTemplateDetailsEpic: Epic<OrderTemplateDetailsAction> = (action$, state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(ORDER_TEMPLATE_ADD_PRODUCT),
    mergeMap(action => api.graphApi<AddedOrderTemplateLineResponse>(addProductMutation, action.payload)
    .pipe(
      map(({ orderTemplates }) =>
        addedProductToTemplate(orderTemplates.addProduct)),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    ),
    ),
  );
};

const orderTemplateSaveChangesEpic: Epic<OrderTemplateDetailsAction> = (action$, _state$, { api, logger }) => {
  const locationChanged$ = action$.pipe(ofType(LOCATION_CHANGED));

  return action$.pipe(
    ofType(ORDER_TEMPLATE_SAVE_CHANGES),
    mergeMap(action => api.graphApi<SaveOrderTemplateResponse>(saveChangesMutation, action.payload).pipe(
      map(({ orderTemplates }) => 
        orderTemplateSavedChanges(orderTemplates && orderTemplates.saveChanges)),
      retryWithToast(action$, logger),
      takeUntil(locationChanged$),
    )),
  );
};

type SaveOrderTemplateResponse = {
  orderTemplates: {
    saveChanges: SaveOrderTemplateResult;
  } | null;
};


export default combineEpics(
  loadOrderTemplateDetailsEpic,
  addLineToOrderTemplateDetailsEpic,
  orderTemplateSaveChangesEpic,
);

function isTrackingEnabled(state: AppState) {
  return state.analytics && state.analytics.isTrackingEnabled;
}