import type {
  OrderTemplate,
  OrderTemplateLine,
  SaveOrderTemplateResult,
} from './types';

export const ORDER_TEMPLATES_REQUESTED = 'ORDER_TEMPLATES_REQUESTED' as const;
export const loadOrderTemplates = () => ({ type: ORDER_TEMPLATES_REQUESTED });

export const ORDER_TEMPLATES_RECEIVED = 'ORDER_TEMPLATES_RECEIVED' as const;
export const orderTemplatesReceived = ({ orderTemplates }: { orderTemplates: OrderTemplate[] | null }) => ({
  type: ORDER_TEMPLATES_RECEIVED,
  payload: orderTemplates,
});
export type OrderTemplatesReceivedAction = ReturnType<typeof orderTemplatesReceived>;

export const ORDER_TEMPLATE_LINES_REQUESTED = 'ORDER_TEMPLATE_LINES_REQUESTED' as const;
export const loadOrderTemplateLines = (id: string) => ({
  type: ORDER_TEMPLATE_LINES_REQUESTED,
  payload: { id },
});

export const ORDER_TEMPLATE_LINES_RECEIVED = 'ORDER_TEMPLATE_LINES_RECEIVED' as const;
export const orderTemplateLinesReceived = (id: string, orderTemplateLines: OrderTemplateLine[] | null = null) => ({
  type: ORDER_TEMPLATE_LINES_RECEIVED,
  payload: { id, orderTemplateLines },
});
export type OrderTemplatesLinesReceivedAction = ReturnType<typeof orderTemplateLinesReceived>;

export const ORDER_TEMPLATES_ADDING_REQUESTED = 'ORDER_TEMPLATES_ADDING_REQUESTED' as const;
export const addToBasket = (ids: string[]) => ({
  type: ORDER_TEMPLATES_ADDING_REQUESTED,
  payload: { ids },
});

export const ORDER_TEMPLATES_ADDED = 'ORDER_TEMPLATES_ADDED' as const;
export const orderTemplatesAdded = (countAdded: number) => ({
  type: ORDER_TEMPLATES_ADDED,
  payload: countAdded,
});
export type OrderTemplatesAddedAction = ReturnType<typeof orderTemplatesAdded>;

export const ORDER_TEMPLATES_REMOVAL_REQUESTED = 'ORDER_TEMPLATES_REMOVAL_REQUESTED' as const;
export const removeTemplates = (ids: string[]) => ({
  type: ORDER_TEMPLATES_REMOVAL_REQUESTED,
  payload: { ids },
});

export const ORDER_TEMPLATES_REMOVED = 'ORDER_TEMPLATES_REMOVED' as const;
export const orderTemplatesRemoved = (ids: string[]) => ({
  type: ORDER_TEMPLATES_REMOVED,
  payload: ids,
});
export type OrderTemplatesRemovedAction = ReturnType<typeof orderTemplatesRemoved>;

export const ORDER_TEMPLATES_FILTERED = 'ORDER_TEMPLATES_FILTERED' as const;
export const filterOrderTemplates = (name: string) => ({ 
  type: ORDER_TEMPLATES_FILTERED,
  payload: { name },
});

export const ORDER_TEMPLATE_CREATE = 'ORDER_TEMPLATE_CREATE' as const;
export const createOrderTemplate = (input: { name: string }) => ({
  type: ORDER_TEMPLATE_CREATE,
  payload: { input },
});

export const ORDER_TEMPLATE_CREATED = 'ORDER_TEMPLATE_CREATED' as const;
export const orderTemplateCreatingResultReceived = (savingResult: SaveOrderTemplateResult) => ({
  type: ORDER_TEMPLATE_CREATED,
  payload: { savingResult },
});

export type OrderTemplatesCreatedAction = ReturnType<typeof orderTemplateCreatingResultReceived>;

export type OrderTemplateAction = ReturnType<
  | typeof loadOrderTemplates
  | typeof orderTemplatesReceived
  | typeof loadOrderTemplateLines
  | typeof orderTemplateLinesReceived
  | typeof addToBasket
  | typeof orderTemplatesAdded
  | typeof removeTemplates
  | typeof orderTemplatesRemoved
  | typeof filterOrderTemplates
  | typeof createOrderTemplate
  | typeof orderTemplateCreatingResultReceived
>;
