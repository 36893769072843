export default Object.freeze([
  {
    columns: [
      {
        contentBlocks: [
          {
            id: 'uvlc2nxrm',
            model: {
              subLevel_AllCaps: false,
              subLevel_ShowArrowInFrontOfSubItem: false,
              topLevel_AllCaps: true,
              topLevel_ShowActiveItemUnderline: true,
            },
            name: 'MobileMainMenu',
          },
          {
            horizontalAlignment: 'CENTER',
            id: '5mbvfbkv8', 
            name: 'Logo',
            spacing: {
              margin: '8px 0px 8px 0px',
            },
            stretchWidth: true,
          },
          {
            horizontalAlignment: 'CENTER',
            id: '1bpunu17g',
            model: {
              content_ShowExpandIcon: false,
              content_ThemeFontSize: 'Regular',
            },
            name: 'MobileAccountMenu',
          },
          {
            id: 'dd19aqk6x',
            model: {
              basketLink_ShowNumberOfProductsAsBadge: false,
              basketLink_ThemeFontSize: 'Regular',
              showMiniatureOnBasket: false,
            },
            name: 'MobileBasketSummary',
          },
        ],
        contentOrientation: 'HORIZONTAL',
        id: 'xktl3cvkt',
      },
    ],
    fullWidth: true,
    id: 'wtxp1m9wj',
    spacing: {
      hideSpaces: true,
      margin: null,
      padding: null,
    },
    verticalAlignment: 'MIDDLE',
  },
  {
    columns: [
      {
        border: {
          color: '#E6E6E6',
          radius: null,
          style: 'solid',
          width: '1px 0px',
        },
        contentBlocks: [
          {
            id: 'vqfu4wer4',
            model: {
              content_AllCaps: false,
              content_ItemFontSize: 'Regular',
              content_ShowExpandIcon: false,
              content_ShowFlags: true,
            },
            name: 'MobileLanguageSelector',
            spacing: {
              margin: '0 8px 0 0',
            },
          },
          {
            id: 'swxso5p8e',
            model: {
              box_BorderStyle: 1,
              box_BorderWidth: '1px',
              box_CornerRadius: '2px',
              box_ThemeFontSize: 'Regular',
              button_CornerRadius: '0px 2px 2px 0px',
              button_Layout: 0,
              button_ThemeFontSize: 'Regular',
              suggestions_ShowThumbnails: true,
              suggestions_ThemeFontSize: 'Regular',
            },
            name: 'SearchBar',
            stretchWidth: true,
          },
          {
            id: '1epqvnhhg',
            name: 'ProductSuggestions',
            spacing: {
              margin: '0 0 0 8px',
            },
          },
        ],
        contentOrientation: 'HORIZONTAL',
        id: 'eg0bl5l02',
        padding: '8px',
      },
    ],
    fullWidth: true,
    id: 'atqugdj8d',
    spacing: {
      hideSpaces: true,
      margin: null,
      padding: null,
    },
    verticalAlignment: 'MIDDLE',
  },
]);
